<template>
  <v-layout class="detail">
    <div class="detail__content">
      <h1 class="detail__content__title" v-if="payment.type == 'documentUpload'">{{$t(payment.label)}}</h1>
      <h1 class="detail__content__title" v-else-if="payment.type == 'extraPayment'">{{payment.label}} : €{{payment.price.amount}}</h1>
      <h1 class="detail__content__title" v-else>{{ $t(`general.payment-types.${payment.label}`) }} : €{{payment.price.amount}}</h1>
      <div class="detail__content__text" v-if="payment.type == 'documentUpload'"
        v-html="$t(`general.document.${payment.label}`,{
          startDate:`${contractPeriod.startDate}`,endDate:`${contractPeriod.endDate}`
        })">
       
      </div>
      <div class="detail__content__text" v-else v-html="$t(`general.payment-types.${payment.type == 'extraPayment'? 'extraPayment.':''}${payment.price['paymentMethod']}`, {
          type: $t(`general.payment-types.${payment.key}-text`),
          bankAccountNumber: payment.price['bankAccount'],
          bankAccountName: payment.price['currentOwner'],
        })">
      
      </div>
      <v-card-actions v-if="payment.type == 'documentUpload'">
        <v-layout>
          <v-flex class="file-container">
            <v-file-input
                background-color="rgba(60, 60, 127, 0.1)"
                color="#3c3c7f"
                show-size
                outlined
                class="file-container__input"
                prepend-inner-icon="mdi-paperclip"
                prepend-icon=""
                accept="image/*,.pdf"
                v-model="file"
                :placeholder="$t('general.document.proof')"
                hide-details="true"
            ></v-file-input>
          </v-flex>
        </v-layout>
      </v-card-actions>
      <v-card-actions v-else-if="payment.price['paymentMethod'] === 'ongoingtask' || payment.price['paymentMethod'] === 'onetimetask'" class="">
        <v-layout>
          <v-flex class="file-container">
            <v-file-input
                background-color="rgba(60, 60, 127, 0.1)"
                color="#3c3c7f"
                show-size
                outlined
                class="file-container__input"
                prepend-inner-icon="mdi-paperclip"
                prepend-icon=""
                accept="image/*,.pdf"
                v-model="file"
                :placeholder="$t('general.payment.proof')"
                hide-details="true"
            ></v-file-input>
          </v-flex>
        </v-layout>
      </v-card-actions>

      <v-card-actions v-else-if="payment.price['paymentMethod'] === 'recurring'" class="mollie-banner d-flex justify-center align-baseline mb-n10">
        <div class="ml-2"><span style="padding-bottom:5px;">{{ $t('general.payment.pay-with-twikey') }}</span></div>
        <v-img
          class="ml-2" style="max-height: 26px; max-width: 88px;padding: 20px;"
          :src="twikeyLogo"
        >
        </v-img>
      </v-card-actions>
<!--
       <v-card-actions v-else-if="payment.price['paymentMethod'] === 'recurring'" v-on:click.stop>
        <v-layout class="payment-form">
          <v-flex class="payment-form__item">
            <p class="text-left mb-1 label" v-html="$t('general.payment.bank-account-number')"></p>
            <v-text-field
                background-color="#f5f5f5"
                class="text-field"
                v-on:click.prevent
                v-on:click.stop
                :rules="bankAccountRules"
                v-model="detail.iban"
                outlined
                @change="$emit('updatePayment', detail)"
            ></v-text-field>
          </v-flex>

          <v-flex class="payment-form__item">
            <p class="text-left mb-1 label" v-html="$t('general.payment.bank-account-firstname')"></p>
            <v-text-field
                background-color="#f5f5f5"
                class="text-field"
                single-line
                hide-details
                v-on:click.prevent
                v-on:click.stop
                v-model="detail.firstName"
                :rules="nameRules"
                outlined
                @change="$emit('updatePayment', detail)"
            ></v-text-field>
          </v-flex>
          <v-flex class="payment-form__item">
            <p class="text-left mb-1 label" v-html="$t('general.payment.bank-account-lastname')"></p>
            <v-text-field
                background-color="#f5f5f5"
                class="text-field"
                single-line
                hide-details
                v-on:click.prevent
                v-on:click.stop
                v-model="detail.lastName"
                :rules="nameRules"
                outlined
                @change="$emit('updatePayment', detail)"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-card-actions> 
    -->

      <v-card-actions v-else class="mollie-banner d-flex justify-center align-baseline mb-n10">
        {{ $t('general.payment.pay-with-mollie') }}
        <v-img
          class="ml-2"
          :src="mollieLogo"
          max-width="44px"
          max-height="13px"
        >
        </v-img>
      </v-card-actions>

      <v-alert
        class="error-box"
        v-if="showError"
        color="red"
        text
        type="warning"
        v-html="$t('general.payment.error')"
      >
      </v-alert>

      <v-btn 
        class="mt-2"
        v-if="showError" 
        @click="retryPayment" 
        v-html="$t('general.payment.retry')">
      </v-btn>

      <div class="confirm">

        
        <v-btn
          :disabled="!file"
          v-if="payment.type == 'documentUpload'"
          :loading="loading"
          depressed
          class="confirm__button"
          @click="confirmData()"
          v-html="$t('general.payment.send')"
        >     
        </v-btn>
        <v-btn
          :disabled="!file"
          v-else-if="!showError && payment.price['paymentMethod'] === 'ongoingtask' || payment.price['paymentMethod'] === 'onetimetask'"
          :loading="loading"
          depressed
          class="confirm__button"
          @click="confirmData()"
          v-html="confirmText"
        >     
        </v-btn>

         <!-- <v-btn
          :disabled="!detail.firstName || !detail.lastName || !detail.iban"
          v-else-if="!showError && payment.price['paymentMethod'] === 'recurring'"
          :loading="loading"
          depressed
          class="confirm__button"
          @click="confirmData()"
          v-html="confirmText"
        >     
        </v-btn> -->

        <v-btn
          v-else-if="!showError"
          :loading="loading"
          depressed
          class="confirm__button"
          @click="confirmData()"
          v-html="confirmText"
        >     
        </v-btn>
      </div>
    </div>

    <div class="bottom">
      <p @click="closeModal()" class="bottom__close ma-0 d-flex">
        <v-icon>mdi-menu-left</v-icon>
        {{ $t(`general.payment.back`) }}
      </p>
    </div>
  </v-layout>
</template>

<script>
  import {validateIbanChecksum} from '../../../utils/iban';

  export default {
    name: 'payment-detail',
    props: {
      payment: {
        type: Object
      },
      showError: {
        type: Boolean
      }
		},
    data() {
      return {
        disableButton: true,
        loading: false,
        file: null,
        bankAccountRules: [
          (v) => !!v || this.$t('error.general.not-empty'),
          (v) => validateIbanChecksum(v) || this.$t('error.general.not-valid-iban')
        ],
        nameRules: [
          (v) => !!v || this.$t('error.general.not-empty')
        ],
        detail: {
          id: this.payment['key'],
          firstName: '',
          lastName: '',
          iban: '',
        },
        contractPeriod: this.payment.contractPeriod ? this.payment.contractPeriod  : {startDate:'',endDate:''}
      }
    },
    mounted() {
      console.log(this.payment)
    },
    computed: {
      mollieLogo() {
        return require(`@assets/mollie.svg`);
      },
      twikeyLogo() {
        return require(`@assets/twikey.svg`);
      },

      confirmText() {
        if (this.payment.price['paymentMethod'] === 'ongoingtask' || this.payment.price['paymentMethod'] === 'onetimetask' ) {//|| this.payment.price['paymentMethod'] === 'recurring'
          return this.$t('general.payment.send') 
        } else {
          return this.$t('general.payment.pay') 
        }
      }
    },
    methods: {
      retryPayment() {
        this.loading = false;
        this.detail.firstName = '';
        this.detail.lastName = '';
        this.detail.iban = '';
        this.$emit('removeError', false);
      },
      confirmData() {
        if(this.payment.type == 'documentUpload'){
          this.$emit('uploadFile', {fileToUpload:this.file,uploadInfo:{key:this.payment.key}});
        } else if (this.payment.price['paymentMethod'] === 'ongoingtask' || this.payment.price['paymentMethod'] === 'onetimetask' && this.file) {
          this.loading = true;
          this.$emit('uploadFile', {fileToUpload:this.file,uploadInfo:null});
        } else if (this.payment.price['paymentMethod'] === 'recurring') {
          this.loading = true;
          this.$emit('uploadDetails', this.detail);
        } else {
          this.loading = true;
          this.$emit('togglePayment', this.payment);
        }
      },
      closeModal() {
        this.detail.firstName = null,
        this.detail.lastName = null,
        this.detail.iban = null,
        this.$emit('close')
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';

  .error-box {
    margin: 1rem 0 0;
    

    &::v-deep p {
      margin-bottom: 0 !important;
    }
  }

  .payment-form {
    display: block !important;

    &__item {
      display: inline-block;
      padding: 1rem 0.5rem 0;
      width: 50%;

      p {
        font-size: 0.75rem;
      }

      .text-field {
        // background: #f3f0f0;
      }

      .text-field.v-text-field>.v-input__control>.v-input__slot:before {
          border-style: none !important;
          border-color: red !important; 
      }
      .text-field.v-text-field>.v-input__control>.v-input__slot:after {
          border-style: none !important;
          border-color: red !important;
      }

      .v-text-field--outlined fieldset {
        border: 0px;
        border-width: 0px;
      }

      &:first-child {
        width: 100%;
        margin-top: 0.5rem;
      }
    }
  }

  .detail {
    padding: 8rem 0;
    background: #fff;
    min-height: 100%;
    position: relative;
    display: flex;
    justify-content: center;

    &__content {
      max-width: 32.5rem;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      text-align: center;
      color: #3c3c7f;

      &__title {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
      }

      &__text {
        font-size: 0.875rem;
        line-height: 2;
        margin: 0 0 1.75rem;
      }
    }
  }

  .bottom {
    background: #ededf3;
    position: fixed;
    bottom: 0;
    width: 100%;

    &__close {
      font-size: 1rem;
      font-weight: bold;
      padding: 1.5rem 2rem;
      color: #3c3c7f;
      opacity: 0.5;
      cursor: pointer;
    }
  }

  .file-container {
    background: none;
    display: flex;
    justify-content: center;
  }

  .confirm {
    margin-top: 3rem;

    &__button {
      background-color: #3c3c7f !important;
      color: #fff;
      min-width: fit-content !important;
      height: auto !important;
      padding: 1rem 1.25rem !important;
    }
  }

  .mollie-banner {
    font-size: 0.875rem;
  }

  @media screen and (max-width: 37.5rem) {
    .detail {
      padding: 0 1rem !important;
      align-content: flex-start;
      padding-top: 8.5rem !important;
      min-height: 100%;

      &__content {
        padding-bottom: 6rem;
        justify-content: flex-start !important;

        &__text {
          margin-bottom: 1rem
        }
      }
    }

    .payment-form {
      &__item {
        width: 100%;

        .label {
          font-size: 1rem;
        }
      }
    }

    .confirm {
      margin-top: 3rem;
    }
  }
</style>
