<template>
	<v-layout row wrap class="pb-5 justify-center payments">
		<v-flex class="px-6 payments__list" >
			<v-progress-circular
								v-if="loadingPayments"
								:size="50"
								color="primary"
								indeterminate
			></v-progress-circular>
			<div>
				<v-form>
					<payment-list-item v-for="payment in payments"
														:selectedPayments="selectedPayments"
														:payment="payment"
														:key="payment.key"
														@toggle="togglePayment"
														@updatePayment="updatePayment"
														@removeError="removeError"
														@uploadFile="uploadFile"
														@completePayment="requestPay"
														:showError.sync="showError"
														:priceWidth="priceWidth"
					>
					</payment-list-item>
					<!--
					<div @click="$emit('toggle', )" class="upload_doc white--text item-full-width justify-space-between d-flex flex-row">
						<div class="upload-title d-flex flex-row">upload doc</div>
						<div class="upload-status d-flex flex-row">
							<div class="status-txt body-1 ma-0 pr-3 grey--text text--lighten-1 text-uppercase">stat</div>
							<div>
								<v-icon medium left class="ma-0" color="green accent-2">mdi-check-circle</v-icon>
							</div>
						</div>
					</div>-->
				</v-form>
			</div>
		</v-flex>
	</v-layout>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import { ACTIONS } from '../payment.constants';
	import PaymentListItem from './payment-list-item';
	import PaymentListExtra from './payment-extras';
	import PaymentCountdown from './payment-countdown';
	import api from '../../../services/backendApi';
	import { isNil, pathOr, isEmpty, filter } from 'ramda';
	import { ACTIONS as TOAST_ACTIONS } from '../../../components/toast/toast.constants';
	import { validateIbanChecksum } from '../../../utils/iban';
	import {formatDate} from '../../../components/data-filter/helpers/date.helpers';

	export default {
		name: 'PaymentList',
		components: {
			PaymentListItem,
			'payment-extras': PaymentListExtra,
			PaymentCountdown
		},
		props: ['company'],
		data() {
			return {
				showError: false,
				loadingPayments: true,
				selectedPayments: [],
				selectedExtraPayments: [],
				disabled: true,
        waitForResponse:false,
				paymentsInfo: null,
				basicUrl: process.env.VUE_APP_API_URL,
				payments: [],
				paymentDue: {},
				extras: [],
				bankAccountInfo: [],
				ecurringInfo: null,
				bankAcountData: {},
        documentToUpload: null,
        brand:'',
				priceWidth: null,
				contractId: null,
				startDate:"",
			endDate:""
			}
		},
		computed: {
			...mapState({
			}),
			isValidRecurring() {
				const payment = this.ecurringInfo;

				return payment
					? filter(isEmpty, [
							payment.firstName,
							payment.name,
							payment.iban,
						]).length === 0
						&& validateIbanChecksum(payment.iban)
					: false;
			}
		},
		mounted() {
			this.contractId = this.$route.query.contractId;
			api.get(`${this.basicUrl}/payments/info?contractId=${this.$route.query.contractId}`)
			.then(({ data }) => {
				this.paymentDue = {...data.data.paymentDue};
				this.startDate = formatDate(data.data.startDate.toString());
					this.endDate = formatDate(data.data.endDate);

        if(data.data.tenantLocale){
          this.$i18n.locale = pathOr({}, ['data', 'tenantLocale'], data);
        }

				this.bankAccountInfo = pathOr({}, ['data', 'bankAccount'], data);
				const fees = pathOr({}, ['data', 'fees'], data);
				const extras = pathOr({}, ['data', 'fees','extraPayments'], data);
				const prices = pathOr({}, ['data', 'prices'], data);
				const registrationDocumentStatus = pathOr(true, ['data', 'registrationDocument'], data);

        		delete fees.extraPayments;

				const defaultPayments = Object.keys(fees).reduce((prevState, key) => [
					...prevState,
					{
						...fees[key],
						key,
            			type: key,
						label: key,
						price: prices[key] || null,
					}
				], []);
				this.loadingPayments = false;

        const extraPayments = Object.keys(extras).reduce((prevState,key)=>[
          ...prevState,
          {
						checked: extras[key].checked,
						status: extras[key].status,
            key:`extraPayment_${key}`,
            type:'extraPayment',
            label: extras[key].title,
            info: extras[key].info,
            notAvailable:false,
            extraPaymentId: extras[key]._id,
            price: {
              amount : extras[key].amount,
              bankAccount: extras[key].payToBankAccount,
              companyId: extras[key].companyId,
              currentOwner: extras[key].payToName,
              ownerId: extras[key].ownerId,
              paymentMethod: extras[key].paymentMethod,
              subscriptionPlan: extras[key].subscriptionPlan
            }
          }
        ],[]);
		const uploadDoc= registrationDocumentStatus != 'NA' ? {
			checked: registrationDocumentStatus == 'APPROVED' ,
			status: registrationDocumentStatus != 'NOT_PRESENT',
			key:'reg_document',
			type:'documentUpload',
			label:'registration_document',
			info:'Opladen van school registratie document',
			notAvailable:false,
			price:{},
			contractPeriod:{startDate:this.startDate,endDate:this.endDate}
		} : {};

        this.payments = [...defaultPayments,...extraPayments, uploadDoc];

				// GET WIDTH OF PRICE
				const arr = [];
				for (let i = 0; i < this.payments.length; i++) {
					const element = this.payments[i];
					if (element.price) {
						if (element.price.amount) {
							arr.push(element.price.amount)
						}
					}
				}

				this.priceWidth = Math.max(...(arr.map(el => el.toString().length)));
			});

      this.brand = this.$route.params.company.toLowerCase();
		},
		methods: {
			...mapActions({
				makeToast: TOAST_ACTIONS.MAKE_TOAST,
				uploadDocument: ACTIONS.UPLOAD_DOCUMENT
			}),
			back() {
				this.$router.push({ path: 'payment-info', query: this.$route.query });
			},
			togglePayment(payment) {

				console.log(payment);
				this.selectedPayments = [];
				this.selectedPayments.push(payment);

				if (this.selectedPayments.length === 0 || ((payment.price.paymentMethod === 'ongoingtask' || payment.price.paymentMethod === 'onetimetask') && this.documentToUpload == null) || (payment.price.paymentMethod === 'recurring' && !this.isValidRecurring)) {
					this.disabled = true;
				} else {
					this.disabled = false;
				}

			},
			uploadFile(val) {
				const file = val.file.fileToUpload;
				const info = val.file.uploadInfo;
				const hasFile = val.hasFile;

				this.disabled = !hasFile;
				this.documentToUpload = file;
				if(info){
					console.log(info);

					//payment.key {contractId,fileType ,file}
					this.uploadDocument({contractId:this.contractId,fileType:info.key,file}).then((res)=>{
						console.log(res)
						window.location.reload();
					}).catch((err)=>{
						console.error(err)
					});
				}else{
					this.requestPay()
				}
			},
			updatePayment(val) {
				this.ecurringInfo = val;
				this.isValidRecurring ? this.disabled = false : this.disabled = true;

				this.requestPay()
			},
			requestPay() {
        this.waitForResponse = true;
				isNil(this.ecurringInfo) ? this.bankAcountData = {} : this.bankAcountData = { ...this.ecurringInfo };

				let readyToPay = []
				this.selectedPayments.length > 0 ? readyToPay = this.selectedPayments : readyToPay = this.selectedExtraPayments;

				let data = {
					contractId: this.$route.query['contractId'],
					selectedPayment: {
						item: readyToPay[0].type,
						extraPaymentId: readyToPay[0].extraPaymentId || null,
						bankAccount: {
							...this.bankAcountData
						}
					}
				}

				const config = { headers: { 'Content-Type': 'multipart/form-data' } };

        const formData = new FormData();
        formData.append('contractId',data.contractId);
        formData.append('selectedPayment',JSON.stringify(data.selectedPayment)  );

        if(this.documentToUpload != null){
					formData.append('document',this.documentToUpload);
        }

				api.post(`${this.basicUrl}/payments/pay`, formData, config)
				.then(response => {
          this.waitForResponse = false;
					window.location.href = response.data;
				}).catch(err=>{
					this.showError = true;
          this.waitForResponse = false;
          this.makeToast({ message:'Betalingen niet gelukt', type: 'error' });
        });
			},
			removeError(val) {
				console.log('eagle has landed = ', val)
				this.showError = val;
			}
		}
	};
</script>


<style lang="scss" scoped>
@import "@styles/colors.scss";
	.line {
		width: 30px;
		height: 3px;
		color: #333; /* old IE */
		background-color: #333; /* Modern Browsers */
	}

	.upload_doc{
		box-shadow: none !important;
		background: rgba(255, 255, 255, 0.2) !important;
		min-height: 3rem;
		border-radius: 10px !important;
		font-size: 1rem !important;
		align-items: center !important;
		padding: 0 16px;
		.upload-title{

		}

		.upload-status{
			align-items: center !important;

			.status-txt{
				font-size: 0.8rem !important;
			}

		}
	}


	@media screen and (max-width: 600px) {
		.payments {
			max-width: 100%;
			margin: 0 !important;

			&__list {
				padding: 0 !important;
			}
		}

		.pay-btn {
			margin: 15px 0 0 0;
			width: 77.5%;
			flex: initial;
		}
	}
</style>
