import {paymentThanksService} from './payment-thanks.service'

const thanksInitState = {
    isLoading: false,
    error: null,
    redirectUrl: null,
    errorList:null,
    isActivated:false,
};

export const THANKS_MUTATIONS = {
    SET_LOADING: `payment-thanks/SET_LOADING`,
    SET_ERROR: `payment-thanks/SET_ERROR`,
    SET_PAGE_DATA: `payment-thanks/SET_PAGE_DATA`,
};

export const thanksMutations = {
    [THANKS_MUTATIONS.SET_LOADING](state, isLoading) {
        state.isLoading = isLoading;
      },
      [THANKS_MUTATIONS.SET_ERROR](state, error) {
        state.error = error;
        state.isLoading = false;
      },
      // [THANKS_MUTATIONS.SET_REDIRECT_URL](state, url) {
      //   state.redirectUrl = url;
      //   state.isLoading = false;
      //   state.error = null;
      // },
      [THANKS_MUTATIONS.SET_PAGE_DATA](state, data) {
        state.errorList = data.errors;
        state.redirectUrl = data.redirectUrl;
        state.isActivated = data.activated;
        state.isLoading = false;
        state.error = null;
      },
    };

export const THANKS_ACTIONS = {
    // GET_REDIRECT_URL: `payment-thanks/GET_REDIRECT_URL`,
    GET_PAGE_DATA: `payment-thanks/GET_PAGE_DATA`,
  };

const thanksActions = {
    // [THANKS_ACTIONS.GET_REDIRECT_URL]({commit},twikeyId) {
    //     commit(THANKS_MUTATIONS.SET_LOADING,true);

    //     return paymentThanksService.getReturnPaymentPage(twikeyId)
    //         .then(({data})=>{
    //             commit(THANKS_MUTATIONS.SET_REDIRECT_URL,data);
    //         })
    //         .catch((error)=>{
    //             commit(THANKS_MUTATIONS.SET_ERROR,error);
    //         });
    // },
    [THANKS_ACTIONS.GET_PAGE_DATA]({commit},twikeyId) {
      commit(THANKS_MUTATIONS.SET_LOADING,true);

      return paymentThanksService.getLandingPageInfo(twikeyId)
          .then(({data})=>{

              commit(THANKS_MUTATIONS.SET_PAGE_DATA,data);
          })
          .catch((error)=>{
              commit(THANKS_MUTATIONS.SET_ERROR,error);
          });
  }//getLandingPageInfo
};

export const store = {
    state: thanksInitState,
    mutations: thanksMutations,
    actions: thanksActions,
  };