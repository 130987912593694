<template>
	<v-flex>
		<v-layout row wrap class="pb-5 justify-center align-center-img ">
            <div class="progress-load">
                <v-progress-circular v-if="showLoading" :size="30" color="primary" indeterminate></v-progress-circular>
            </div>
            <v-alert
                class="error-box p-error-box"
                v-if="showError"
                color="red"
                text
                type="warning"
                v-html="$t('general.payment.error')"
            >
            </v-alert>

            <v-img v-if="showError"
					alt="checkMark"
					class="svg"
					src="@assets/failed.svg"
					contain
					height="50vh"
					width="40px"
			/>
			<v-img v-else
					alt="checkMark"
					class="svg"
					src="@assets/success.svg"
					contain
					height="50vh"
					width="40px"
			/>
		</v-layout>
		<v-layout row wrap class="pb-5 justify-center ">
			<h1 class="white--text"></h1>
		</v-layout>
	</v-flex>

</template>

<script>
import { isNil, pathOr } from 'ramda';
import { mapActions, mapState } from 'vuex';
import { THANKS_ACTIONS } from "./payment-thanks.store";
import { ACTIONS as TOAST_ACTIONS } from "../../../components/toast/toast.constants.js";

export default {
    components:{},
        props : { },
        data(){
            return {
                twikeyId: null,
                counter: 0,
                checkInterval:null,
                showError:false,
                showLoading:false
            }
        },
        mounted(){
            this.twikeyId = this.$route.query.twikeyId;
            this.counter =0;
            this.showLoading = true;
            this.getPageData(this.twikeyId);

            this.checkInterval = setInterval(()=>{
                this.counter++;
                if(this.counter == 30){
                    clearInterval(this.checkInterval);
                    this.showError = true;
                    this.showLoading = false;
                    // if(this.errorList && this.errorList.length > 0){
                        
                    // }
                }
                this.getPageData(this.twikeyId);
            },1000);
        },
        computed:{
            ...mapState({
                redirectUrl: (state)=> pathOr(null,['payment-thanks','redirectUrl'],state),
                errorList: (state)=> pathOr(null,['payment-thanks','errorList'],state),
                isActivated:(state)=> pathOr(null,['payment-thanks','isActivated'],state),
                isLoading: (state) => pathOr(false, ['payment-thanks', "isLoading"], state),
                error: (state) => pathOr(null, ['payment-thanks', "error"], state),
            })
        },
        methods: {
            ...mapActions({
                getPageData : THANKS_ACTIONS.GET_PAGE_DATA,
                makeToast: TOAST_ACTIONS.MAKE_TOAST,
            })
            
        },
        watch:{
            isActivated:function(){
                if (this.isActivated) {
                 setTimeout(() => this.$router.push(this.redirectUrl), 500);
                }
            },
            error: function () {
                if (!isNil(this.error)) {
                    this.makeToast({
                    message: "Er ging iets fout bij het ophalen van de data.",
                    type: "error",
                    });
                }
                },
        }
    }
</script>

<style lang="scss" scoped>
.svg {
	width: 50vh;
}

.align-center-img{
    align-content: center;
    height: 90vh;
}

.p-error-box{
    position: absolute;
    z-index: 3;
    top: 69vh;
}
.progress-load{
    position: absolute;
    z-index: 2;
    top: 50vh;
}
</style>
