export const MODULE_NAME = 'payment';

export const ACTIONS = {
	GET_PAYMENT_REQUEST: `${MODULE_NAME}/GET_PAYMENT_REQUEST`,
	SET_PAYMENT_DETAILS: `${MODULE_NAME}/SET_PAYMENT_DETAILS`,
	PAY: `${MODULE_NAME}/PAY`,
	UPLOAD_DOCUMENT: `${MODULE_NAME}/UPLOAD_DOCUMENT`,
};


export const MUTATIONS = {
	SET_PAYMENT_REQUEST: `${MODULE_NAME}/SET_PAYMENT_REQUEST`,
	SET_PAYMENT_DETAILS: `${MODULE_NAME}/SET_PAYMENT_DETAILS`,
	SET_IS_LOADING: `${MODULE_NAME}/SET_IS_LOADING`,
};
