import api from '../../../services/backendApi';

const config = { headers: { 'Content-Type': 'application/json' } };

export const paymentThanksService = {
    getReturnPaymentPage: (twikeyInfoId) => {
        return api.get(`/payments/twikey/redirect/${twikeyInfoId}`,{config})
    },

    getLandingPageInfo: (twikeyInfoId) => {
        return api.get(`/payments/twikey/info/${twikeyInfoId}`,{config})
    },
}