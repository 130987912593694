<template>
  <div class="calendar" v-if="company === 'upkot' && !paymentDue.allPaid">
  <!-- <div class="calendar" v-if="!(paymentDue.daysLeft<-7 || paymentDue.allPaid)"> -->
    <div :class="['calendar-date', paymentDue.statusCode]">
      <p class="calendar--text ma-0">{{paymentDue.daysLeft}}</p>
    </div>

    <div class="calendar-info">
      <p class="calendar--text ma-0" v-html="$t('general.payment.countdown')"></p>
      <span class="calendar-info__warning" v-if="paymentDue.daysLeft<7">
        <v-icon color="#FF8A80"> mdi-alert </v-icon>
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'payment-countdown',
    props: {
      paymentDue: {
        type: Object,
      },
      company: {
        type: String,
      }
		},

    data: vm => ({
    }),

    created() {

		},

    mounted() {
    },

    computed: {

    },

    watch: {

    },

    methods: {

    },
  }
</script>

<style lang="scss" scoped>

@import 'src/styles/global-color-codes.scss';

  .calendar {
    z-index: 1000;
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    // overflow: hidden;
    border-radius: 0.5rem;
    display: flex; 
    flex-direction: row;
    align-items: center;

    &--text {
      font-size: 1.375rem;
      color: #fff;
      font-weight: bold;
      line-height: normal;
    }

    &-date {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }

    &-date,
    &-info {
      padding: 0.5625rem;
    }

    &-info {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      background: #f3f0f0;
      position: relative;

      .calendar--text {
        color: #1f1f5a;
      }

      &__warning {
        position: absolute;
        top: -1.3rem;
        right: -0.7rem;
      }
    }
  }

  @media screen and (max-width: 37.5rem) {
    .calendar {
      width: fit-content;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
    }
  }

</style>
