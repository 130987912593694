<template>
	<v-layout row wrap class="pa-0 justify-center align-center company-page" :class="{ 'company-page__padding': !paymentDue.allPaid }">
		<payment-countdown :paymentDue="paymentDue" :company="company"></payment-countdown>

		<v-flex class="d-flex justify-center ma-0">
			<v-card flat class="card">
				<v-layout class="d-flex flex-column pa-0 justify-center overlay text-center">
					<h2 class="payment-title" v-html="$t('general.payment.overview-title')"></h2>	
					<h3 class="payment-subtitle">
						<div>
							<v-icon medium color="#1acfda" class="mt-n2">mdi-map-marker</v-icon> {{projectName}} | {{unitName}}</div>
						<div>{{startDate}}  - {{ endDate }}</div>
						
						
					</h3>
					<p class="payment-info body-1" v-html="$t( company + '.payment.intro')"></p>

					<v-flex xs12 justify-center>
						<payment-list :company="company"></payment-list>
					</v-flex>
				</v-layout>
			</v-card>
		</v-flex>
	</v-layout>
</template>

<script>
	import PaymentList from './payment-list';
	import api from '../../../services/backendApi';
	import PaymentCountdown from './payment-countdown';
	import {formatDate} from '../../../components/data-filter/helpers/date.helpers';

	export default {
		name: 'PaymentPage',
		components: { 
			PaymentList,
			PaymentCountdown
		},
		data: () => ({
			basicUrl: process.env.VUE_APP_API_URL,
			projectName: "",
			unitName: "",
			paymentDue: {},
			startDate:"",
			endDate:""
		}),
		mounted() {
			api.get(`${this.basicUrl}/payments/info?contractId=${this.$route.query.contractId}`)
				.then(({ data }) => {
					this.unitName = data.data.unitName;
					this.projectName = data.data.projectName;

					this.paymentDue = {...data.data.paymentDue};
					this.startDate = formatDate(data.data.startDate.toString());
					this.endDate = formatDate(data.data.endDate);
				})
		},
		computed: {
			company() {
				return this.$route.params.company.toLowerCase()
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import "@styles/colors.scss";

	.subtitle {
		width: 100%;
		text-align: left;
    font-size: 0.8em;
	}

	.company-page, .filter-block {
		margin: 0;
		width: 100%;
		min-height: 100%;
	}

	.card {
		width: 100%;
		max-width: 35rem;
		box-sizing: border-box;
		background: none;
		border-radius: 15px !important;
	}

	.payment {
		&-title {
			color: #fff;
			font-weight: 900;
			font-size: 1.5rem;
			margin: 0 0 0.375rem;
			text-transform: uppercase;
		}

		&-subtitle {
			color: #1acfda;
			font-weight: 900;
			font-size: 1rem;
			text-transform: uppercase;
		}

		&-info {
			color: #fff;
			margin: 4rem 0 1.5rem 0;
			line-height: 1.75;
		}
	}

	@media screen and (max-width: 600px) {
		.overlay {
			max-width: 100%;
			margin: auto;
		}

		.subtitle {
			font-size: 0.6em
		}
	}

	@media screen and (max-width: 37.5rem) {
		.company-page {
			padding: 0 1rem !important;
			align-content: flex-start;

			&__padding {
				padding-top: 5rem !important;
			}
		}

		.payment {
			&-info {
				margin: 2rem 0 1.5rem 0;
			}
		}
	}
</style>

