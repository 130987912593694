<template>
	<div style="max-width: 45rem; width: 100%">
		<!-- <v-flex xs12 md12 class="px-10 justify-center">
			<v-progress-circular v-if="loadingPayments" :size="50" color="primary" indeterminate></v-progress-circular>
		</v-flex> -->

		<v-flex v-if="!loadingPayments" class="d-flex flex-column mt-15 upload-container">
			<v-layout class="upload-container__content">
				<div class="upload-container__content__item mr-4">
					<v-card @click="dialog = true; id='front'" class="d-flex justify-center" :disabled="imageCheck.front">
						<v-btn elevation="0" class="btn--transform btn--upload pa-6">
							<v-icon medium left color="white">mdi-upload</v-icon>
							<p class="ma-0 white--text" v-html="$t('general.payment-user-info.passport-front')"></p>
						</v-btn>
					</v-card>
				</div>

				<div class="upload-container__content__item">
					<v-card @click="dialog = true; id='back'" class="d-flex justify-center" :disabled="imageCheck.back">
						<v-btn elevation="0" class="btn--transform btn--upload pa-6">
							<v-icon medium left color="white">mdi-upload</v-icon>
							<p class="ma-0 white--text" v-html="$t('general.payment-user-info.passport-back')"></p>
						</v-btn>
					</v-card>
				</div>
			</v-layout>

			<div class="pr-3 upload-container__content__check">
				<v-slide-y-transition>
					<div
						v-if="imageCheck.front"
						class="upload-container__confirmation pa-4 px-6 mt-4"
						elevation="2"
					>
						<div class="d-flex justify-space-between">
							<p class="body-1 ma-0 white--text" v-html="$t('general.payment-user-info.front-check')"></p>
							<v-slide-y-transition>
								<div class="d-flex align-center" v-if="iconCheck.front">
									<div class="d-flex align-center">
										<v-icon small color="blue-grey lighten-3">mdi-close-circle-outline</v-icon>
										<p @click="removeImage('front')" class="remove-text body-1 ma-0 ml-1 mr-6 grey--text text--lighten-1"><u><i>{{ $t('general.payment-user-info.remove') }}</i></u></p>
									</div>
									<v-icon medium left color="green accent-2">mdi-check-circle</v-icon>
								</div>
							</v-slide-y-transition>
						</div>
						<v-progress-linear
							:class="tempSpacing.front"
							:active="loading.front"
							:color="progressColor"
							indeterminate
							rounded
							height="4"
						></v-progress-linear>
					</div>
				</v-slide-y-transition>

				<v-slide-y-transition>
					<div
						v-if="imageCheck.back"
						class="upload-container__confirmation pa-4 px-6 mt-2"
						elevation="2"
					>
						<div class="d-flex justify-space-between">
							<p class="body-1 ma-0 white--text" v-html="$t('general.payment-user-info.back-check')"></p>
							<v-slide-y-transition>
								<div class="d-flex align-center" v-if="iconCheck.back">
									<div class="d-flex align-center">
										<v-icon small color="blue-grey lighten-3">mdi-close-circle-outline</v-icon>
										<p @click="removeImage('back')" class="remove-text body-1 ma-0 ml-1 mr-6 grey--text text--lighten-1"><u><i>{{ $t('general.payment-user-info.remove') }}</i></u></p>
									</div>
									<v-icon medium left color="green accent-2">mdi-check-circle</v-icon>
								</div>
							</v-slide-y-transition>
						</div>
						<v-progress-linear
							:class="tempSpacing.back"
							:active="loading.back"
							:color="progressColor"
							indeterminate
							rounded
							height="4"
						></v-progress-linear>
					</div>
				</v-slide-y-transition>

			</div>
		</v-flex>

		<v-flex class="d-flex py-4 mt-3">
			<v-btn outlined class="btn--upload btn--transform white--text" large :disabled="!valid"
						@click="goToPayment">{{ $t('general.payment-user-info.confirm') }}
			</v-btn>
		</v-flex>

		<v-dialog v-model="dialog" max-width="700px">
			<picture-uploader v-if="dialog" @uploadAvatar="onUploadAvatar($event); dialog=false"></picture-uploader>
		</v-dialog>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ACTIONS } from '../payment.constants';
import { ACTIONS as TOAST_ACTIONS } from '../../../components/toast/toast.constants';
import PictureUploader from '../../../components/file-uploader/picture-uploader';
import api from '../../../services/backendApi';
import { isNil } from 'ramda';

export default {
	name: 'PaymentList',
	components: { PictureUploader },
	props: ['company'],
	data() {
		return {
			imageCheck: {
				front: false,
				back: false,
			},
			loading: {
				front: false,
				back: false,
			},
			iconCheck: {
				front: false,
				back: false
			},
			tempSpacing: {
				front: 'mt-2',
				back: 'mt-2',
			},
			progressColor: 'green accent-2',
			dialog: false,
			id: 'front',
			front: null,
			back: null,
			frontImageBlob: null,
			backImageBlob: null,
      contractId:null,
			email: null,
			valid: false,
			emailRules: [
				v => !!v || this.$t('error.general.not-empty'),
				v => /.+@.+/.test(v) || this.$t('error.general.not-valid-email'),
			],
			basicUrl: process.env.VUE_APP_API_URL,
			contractData: null,
			paymentInfo: null,

			test: null,
			passportCheck: false,
			tenantID: null,
			loadingPayments: true,
		}
	},
	computed: {
		...mapState({
			isSearching: state => state.payment.loading,
		}),
	},
	watch: {
		loading: {
			deep: true,

			handler(val) {
				if (!val) return
				this.uploadLoader(this.id)
			}
		},
		imageCheck: {
			deep: true,

			handler() {
				if (this.imageCheck.front && this.imageCheck.back) {
					this.valid = true
				} else {
					this.valid = false
				}
			}
		}
	},
	mounted() {
    this.contractId = this.$route.query.contractId;
		api.get(`${this.basicUrl}/payments/passportCheck?contractId=${this.contractId}`)
		.then(response => {
			response = response.data.data;
      if(response.tenantLocale){
        this.$i18n.locale = response.tenantLocale;
      }

			this.tenantID = response.tenantId;
			this.passportCheck = response.passportCheck;
	    this.loadingPayments = false;

			if (this.passportCheck === true) {
				this.$router.push({ path: `payment`, query: this.$route.query });
			}
		})

		if (this.paymentDetails) {
			this.front = this.paymentDetails.front;
			this.back = this.paymentDetails.back;
		}
	},
	methods: {
		...mapActions({
			makeToast: TOAST_ACTIONS.MAKE_TOAST,
		}),
		uploadLoader(val) {
			setTimeout(() => (
					this.loading[val] = false,
					this.iconCheck[val] = true,
					this.tempSpacing[val] = 'mt-0'
				), 1500)
		},
		goToPayment() {
			if (this.front && this.back) {
				const formData = new FormData();
				let data = {
					frontImageBlob: this.frontImageBlob,
					backImageBlob: this.backImageBlob,
          contractId: this.contractId
				}

				formData.append('body', JSON.stringify(data));

				if (this.frontImageBlob && this.backImageBlob) {
					formData.append('front', this.frontImageBlob, 'front.jpg' );
					formData.append('back', this.backImageBlob, 'back.jpg' );
				}

				api.patch(`${this.basicUrl}/tenants/${this.tenantID}`, formData, { headers: { 'Content-Type': 'multipart/form-data' }}).then(() => {
					this.$router.push({ path: `${this.company}/payment`, query: this.$route.query });
				}, (error) => {
					this.makeToast({ message: error, type: 'error' });
				})

			} else {
				this.makeToast({ type: 'error' })
			}
		},
		togglePayment(paymentId) {
			const index = this.selectedPayments.indexOf(paymentId);
			if (index === -1) {
				this.selectedPayments.push(paymentId);
			} else {
				this.selectedPayments.splice(index, 1);
			}
		},
		blobToBase64(blob) {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			return new Promise(resolve => {
				reader.onloadend = () => {
					resolve(reader.result);

					if(isNil(this.front) && isNil(this.back)) {
						return
					}	else {
						this.valid = true
					}
				};
			});
		},
		onUploadAvatar(event) {
			this.loading[this.id] = true;
			this.id === 'front' ? this.imageCheck.front = true : this.imageCheck.back = true;

			this[this.id + 'ImageBlob'] = event;
			this.blobToBase64(event).then(res => {
				this[this.id] = res;
			});
		},
		removeImage(id) {
			this.imageCheck[id] = false;
			this.iconCheck[id] = false;
			this.tempSpacing[id] = 'mt-2';
			this.id = null;
		}
	}
};
</script>


<style lang="scss" scoped>
.upload-container {
	&__content {
		&__item {
			.v-card {
				background-color: rgba(255, 255, 255, 0) !important;
				margin-bottom: 0.5rem;

				.btn--upload {
					width: 100%;
				}
			}
		}
	}
	&__confirmation {
		background: rgba(0, 0, 0, 0.15);
		border-radius: 0.75rem
	}
}

.remove-text {
	cursor: pointer
}

.btn {
	&--upload {
		border: 2px dashed rgba(255, 255, 255, 0.15) ;
		background-color: rgba(255, 255, 255, 0.2) !important;

		&::before {
			background-color: rgba(255, 255, 255, 0.2) !important
		}
	}

	&--transform {
		text-transform: initial;
	}
}

@media screen and (max-width: 37.5rem) {
	.upload-container {
		margin-top: 2.5rem !important;
		height: fit-content !important;

		&__content {
			flex-direction: column !important;

			&__check {
				padding: 0 !important;
			}
		}
	}

	.upload-container__content__item,
	.upload-container__confirmation {
		margin-right: 1rem !important;
		margin-left: 0 !important;
	}
}
</style>
