import api from '../../services/backendApi';

const paymentUrl = '/payments';
const tenantUrl = '/tenants';
const contractUrl = '/contracts';
const config = { headers: { 'Content-Type': 'application/json' } };

export default {
	getPaymentRequest(filter) {
		return api.post(`${paymentUrl}/info`, { filter }, config);
	},
	pay(data) {
		const formData = new FormData();
		let dataToSend = {
			...data,
		};
		return api.post(`${paymentUrl}/pay`, dataToSend, config);
	},
	updateUserDetail(data) {
		const formData = new FormData();
		let {frontImageBlob, backImageBlob, ...dataToSend } = data;
		formData.append('body', JSON.stringify(dataToSend));
		if (frontImageBlob && backImageBlob) {
			formData.append('file', frontImageBlob, 'front.jpg' );
			formData.append('file', backImageBlob, 'back.jpg' );
		}
		return api.patch(`${tenantUrl}/`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
	},
	uploadDocument(contractId,documentType,document){
		const formData = new FormData();
		formData.append('document',document);
		formData.append('documentType',documentType);

		return api.post(`${contractUrl}/${contractId}/upload-document`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
	
	}
};
