<template>
	<div class="grid-container">
		<v-row class="d-flex fill-height ma-0 justify-center">
			<v-col class="d-flex flex-column justify-center pa-15 grid-container__content">
				<v-img
						alt="checkMark"
						class="svg"
						src="@assets/kotkip.svg"
						contain
						height="161px"
						width="130px"
				/>

				<h2 class="passport--title passport--width white--text">
					<span class="passport--title__black d-block" v-html="$t('general.payment.passport-title')"></span>
					<span class="passport--title__red d-block" v-html="$t('general.payment.passport-sub-title')"></span>
				</h2>
				<p class="body-1 passport--width passport--text white--text ma-0" v-html="$t('general.payment-user-info.intro')"></p>
				<p class="body-1 passport--width passport--text white--text ma-0" v-html="$t('general.payment-user-info.passport')"></p>
				<information-page :company="company"></information-page>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import InformationPage from './payment-user-info';

	export default {
		name: 'PaymentPage',
		components: { InformationPage },
		data: () => ({
			company: 'upliving'
		}),
		mounted() {
			this.company = this.$route.params.company.toLowerCase();
		}
	};
</script>

<style lang="scss" scoped>
	@import "@styles/colors.scss";

	.svg {
		z-index: 5;
		flex: initial;
		margin-bottom: -2.5rem;
	}

	.passport {
		&--width {
			max-width: 31.25rem;
		}

		&--title {
			z-index: 10;
			font-size: 1.5rem;
			line-height: initial;
			margin-bottom: 2.25rem;
			text-transform: uppercase;
			
			span {
				width: fit-content;
				// margin: 0 auto;
				padding: 2px 19px 0 21px;
			}

			&__black {
				background: #000000;
				// transform: rotate(-2.75deg);
			}

			&__red {
				background: #e63328;
				// transform: rotate(-2.5deg);
			}
		}

		&--text {
			text-align: left;
			font-size: 1rem;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.75;
		}
	}

	.grid-container {
		width: 100%;
		min-height: 100%;

		&__content {
			max-width: 45rem;
		}
	}

	.subtitle {
		width: 100%;
		text-align: left;
		font-size: 1.5em;
	}

	.company-page, .filter-block {
		width: 100vw;
		margin: 0;
	}

	.company-page {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		box-sizing: border-box;
		width: 100%;
	}

	.card {
		border-radius: 15px !important;
	}

	@media screen and (max-width: 37.5rem) {
		.grid-container {
			position: relative;
			margin-top: -27px !important;

			&__content {
				padding: 1rem 1rem !important;
			}
		}
	}
</style>

