<template>
	<!--
		First check if price object is empty, if empty hide payment card
	-->
	<div>
		<v-card
				class="mx-0 my-2 pointer payment-row"
				:class="{'selected-row': isSelected, 'disabled-row': isDisabled}"
				:disabled="isDisabled"
				v-if="payment.type == 'documentUpload' || (payment.price && payment.price.amount > 0 && payment.price['paymentMethod'] && Object.keys(payment.price).length != 0)"

				@click="paymentDetailDialog = true"
		>
			<v-list-item class="payment-row__item" @click="$emit('toggle', payment)">
				<div class="item-full-width d-flex flex-column">
					<div class="d-flex flex-row justify-space-between align-center">
						<div class="d-flex flex-row">
							<p  class="body-1 font-weight-black white--text ma-0 mr-3 price--amount" :class="[dynamicPrice]">
								<span v-if="payment.type == 'documentUpload'"><v-icon class="white--text" >mdi-account-school-outline</v-icon></span>
								<span v-else-if="payment.price['amount']" >€ {{ payment.price['amount'] }}</span>
							</p>
							<p class="body-1 white--text ma-0 text-left price--label" v-if="payment.type == 'extraPayment'">{{payment.label}}</p>
							<p class="body-1 white--text ma-0 text-left price--label" v-else>{{ $t(`general.payment-types.${payment.label}`) }}</p>
						</div>


						<div class="d-flex align-center">
							<p class="payment-check body-1 ma-0 pr-3 grey--text text--lighten-1"  v-if="isDisabled">
								<span v-if="payment.price.paymentMethod === 'recurring'" class="text-uppercase">{{ $t('general.payment.payedRecurring') }}</span>
								<span v-else-if="payment.price.paymentMethod === 'onetimepayment'" class="text-uppercase">{{ $t('general.payment.payed') }}</span>
								<span v-else class="text-uppercase">
									<span v-if="payment.status && payment.checked">{{ $t('general.payment.confirmed') }}</span>
									<span v-else>{{ $t('general.payment.waiting') }}</span>
								</span>
							</p>

							<v-icon v-if="isDisabled && payment.status && payment.checked" medium left class="ma-0" color="green accent-2">mdi-check-circle</v-icon>
							<v-icon v-else-if="isDisabled" medium left class="ma-0" color="orange accent-2">mdi-pause-circle</v-icon>
						</div>
					</div>
				</div>
			</v-list-item>
		</v-card>


			<v-dialog
				persistent
				v-model="paymentDetailDialog"
				fullscreen
				hide-overlay
				transition="dialog-bottom-transition"
			>
				<payment-detail
					@close="paymentDetailDialog = false"
					:payment="payment"
					@uploadFile="fileChange"
					@uploadDetails="updatePayment"
					@togglePayment="togglePayment"
					@removeError="removeError"
					:showError.sync="showError"
				>
				</payment-detail>
			</v-dialog>
	</div>
</template>

<script>
import {validateIbanChecksum} from '../../../utils/iban';
import api from '../../../services/backendApi';
import paymentDetail from './payment-detail';
import moment from 'moment';

export default {
	components: { paymentDetail },
	props: {
		showError: {
			type: Boolean,
		},
		payment: {
			type: Object,
		},
		selectedPayments: {
			type: Array,
		},
		priceWidth: {
			type: Number,
		}
	},
	data() {
		return {
			paymentDetailDialog: false,
			basicUrl: process.env.VUE_APP_API_URL,
			tenantId: null,
			dynamicPrice: "price-width-",
		}
	},
	mounted() {
		this.dynamicPrice = this.dynamicPrice+this.priceWidth
    if (this.payment.paymentDate) {
      const lockPeriod = moment().subtract(15, 'minutes');
      const paymentDate = moment(this.payment.paymentDate);
      const locked = paymentDate.isAfter(lockPeriod);
      if (locked) this.payment.status = true;
    }
	},
	computed: {
		paymentDetail() {
			return this.payment[1]
		},
		name() {
			return this.$t(`general.payment-types.${this.payment.key}`);
		},
		price() {
			return this.payment.price['amount'];
		},
		img() {
			return require(`@assets/icons/${this.payment.type}.svg`);
		},
		isSelected() {
			return this.selectedPayments.filter(p => p.key === this.payment.key).length > 0;;
		},
		isDisabled() {
		//	return this.payment['status'] === true && this.payment['checked'] === true
    // quick fix to give some interaction
    // TODO in V2 adding more statuses
			return this.payment['status'] === true
		}
	},
	methods: {
		fileChange(paymentProof) {
			const uploadFile = paymentProof ? { hasFile: true, file: paymentProof } : { hasFile: false, file: null};
      this.$emit('uploadFile', uploadFile);
		},
		updatePayment(detail) {
			this.$emit('updatePayment', detail);
		},
		togglePayment(payment) {
			this.$emit('completePayment', payment)
		},
		removeError(val) {
			this.$emit('removeError', val)
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@styles/colors.scss";

.price-width {
	@for $i from 1 through 10 {
			&-#{$i} {
					width:#{(($i * 10) + 14)}px;
			}
	}
}

.item {
	&-full-width {
		width: 100%;
	}
}

.payment-row {
	box-shadow: none !important;
	background: rgba(255, 255, 255, 0.2) !important;
	min-height: 3rem;
	border-radius: 10px !important;

	&.selected-row {
		background-color: white;
		border-color: $upliving !important;
	}

	&.disabled-row {
		border-color: #c9c9c9 !important;
	}

	.payment-row__item {
		// background-color: red;
		background-color: rgba(255, 255, 255, 0.2);

		@media screen and (max-width: 600px) {

			.payment-content {
				display: block;
				padding-right: initial;
				margin: 15px 0 0;
			}

			.payment-price,
			.payment-price-payed {
				position: relative;
				right: 0;
				width: auto;
				margin: 30px 0;
			}
		}
	}
}

.check-mark-container {
	position: absolute;
	top: -20px;
	right: 20px;
}

.payment-info{
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
}

.payment-check {
	width: min-content;
	max-width: 10rem;
	font-size: 0.8rem !important;
	line-height: normal;
	overflow-wrap: normal !important;
}

.price {
	&--amount {
		display: flex;
		align-items: center;
		min-width: fit-content;
	}

	&--label {
		display: flex;
		align-items: center;
		line-height: 1;
	}
}

@media screen and (max-width: 37.5rem) {
	.form--inputs {
		margin-top: 1rem;
		display: flex;
    flex-direction: column;

		div {
			max-width: 100% ;
			margin: 0 !important;
		}
	}
}
</style>
