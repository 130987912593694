<template>
	<!--
		First check if price object is empty, if empty hide payment card
	-->

	<v-card
			class="ma-3 my-5 pointer payment-row"
			flat
			:class="{'selected-row': isSelected, 'disabled-row': isDisabled}"
			:disabled="isDisabled"
			v-if="payment.amount > 0 && payment.paymentMethod"
	>
			<!-- v-if="payment.price && Object.keys(payment.price).length != 0" -->

		<v-list-item three-line @click="$emit('toggle', payment)">
			<div class="check-mark-container" v-if="isSelected">
				<v-img
						alt="checkMark"
						class="check-mark"
						src="@assets/icons/check-mark.svg"
						transition="scale-transition"
						width="40px"
				/>
			</div>

			<v-list-item-avatar class="mr-5" tile size="80">
				<v-img
						class="item-logo pa-5"
						:src="img"
						contain
						transition="scale-transition"
						width="25px"
				/>
			</v-list-item-avatar>

			<v-list-item-content class="payment-content">
				<v-list-item-title class="headline mb-1">
          {{payment.title}}
        </v-list-item-title>
				<!-- {{
						$t(`general.payment-types.${payment.paymentMethod}`, {
							type: type,
							bankAccountNumber: payment.payToBankAccount,
							bankAccountName: payment.payToName,
						})
					}} -->
        <v-list-item-subtitle>
           {{payment.info}}
				</v-list-item-subtitle>
				<div class="payment-price align-center" v-if="!isDisabled">
					<p class="price">{{ payment.amount }}</p>
					<p>euro</p>
				</div>
				<div class="payment-price-payed align-center" v-else>
					<p v-if="payment.paymentMethod != 'recurring'" class="text-uppercase">{{ $t('general.payment.payed') }}</p>
					<p v-else class="text-uppercase">{{ $t('general.payment.payedRecurring') }}</p>
				</div>
			</v-list-item-content>
		</v-list-item>
	</v-card>
</template>

<script>
import {validateIbanChecksum} from '../../../utils/iban';

export default {
	props: {
    type: {
			type: String,
		},
		payment: {
			type: Object,
		},
		// paymentRequest: {
		// 	type: Object,
		// },
		selectedPayments: {
			type: Array
		}
	},
	data() {
		return {
			basicUrl: process.env.VUE_APP_API_URL,
			tenantId: null,
			bankAccountRules: [
				(v) => !!v || this.$t('error.general.not-empty'),
				(v) => validateIbanChecksum(v) || this.$t('error.general.not-valid-iban')
			],
			nameRules: [
				(v) => !!v || this.$t('error.general.not-empty')
			],
			detail: {
				id: this.type,
				firstName: '',
				lastName: '',
				iban: '',
			}
		}
	},
	mounted() {
		// console.log('payment list item', this.payment)
    console.log(this.payment);
    console.log(this.selectedPayments);
	},
	computed: {
		paymentDetail() {
			return this.payment[1]
		},
		name() {
			return this.$t(`general.payment-types.${this.type}`);
		},
		price() {
			return this.payment.amount;
		},
		img() {
			return require(`@assets/icons/rent.svg`);
			// return require(`@assets/icons/${this.payment['key']}.svg`);
		},
		isSelected() {
			return this.selectedPayments.filter(p => p.id === this.payment._id).length > 0;
		},
		isDisabled() {
		//	return this.payment['status'] === true && this.payment['checked'] === true
    // quick fix to give some interaction
    // TODO in V2 adding more statuses
			return this.payment['status'] === true
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@styles/colors.scss";

.payment-row {
	background-color: $grey;
	border-radius: 10px !important;
	border: solid 2px;
	border-color: $grey !important;

	&.selected-row {
		background-color: white;
		border-color: $upliving !important;
	}

	&.disabled-row {
		border-color: #c9c9c9 !important;
	}
}

.payment-content {
	padding-right: 200px;
}

.payment-price {
	position: absolute;
	right: 20px;
	width: 200px;
	color: #C7C7C7;
	text-align: center;

	p {
		font-size: 32px;
		line-height: 0;
	}

	.price {
		padding-top: 15px;
		line-height: 40px;
		font-size: 50px;
	}
}

.payment-price-payed {
	position: absolute;
	right: 20px;
	width: 200px;
	color: #C7C7C7;
	text-align: center;

	p {
		padding-top: 17px;
		font-size: 32px;
		font-weight: bolder;
		// line-height: 0;
	}
}

.check-mark-container {
	position: absolute;
	top: -20px;
	right: 20px;
}
</style>
