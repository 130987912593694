import { ACTIONS, MUTATIONS } from './payment.constants';
import paymentService from './payment.service';

const payment = {
	state: {
		loading: false,
		paymentRequest: {},
		paymentDetails: {},
	},
	actions: {
		[ACTIONS.GET_PAYMENT_REQUEST]({ commit }, filter) {
			commit(MUTATIONS.SET_IS_LOADING, true);
			return new Promise((resolve, reject) => {
				paymentService.getPaymentRequest(filter)
					.then((result) => {
						commit(MUTATIONS.SET_PAYMENT_REQUEST, result.data);
						resolve(result.data);
					}, (error) => {
						reject(error);
					})
					.finally(() => {
						commit(MUTATIONS.SET_IS_LOADING, false);
					});
			});
		},
		[ACTIONS.PAY]({ state, commit }, filter) {
			commit(MUTATIONS.SET_IS_LOADING, true);
			return new Promise((resolve, reject) => {
				console.log(filter)
				paymentService.pay(filter)
					.then((result) => {
						window.location = result.data.url;
					}, (error) => {
						reject(error);
					})
					.finally(() => {
						commit(MUTATIONS.SET_IS_LOADING, false);
					});
			});
		},
		[ACTIONS.SET_PAYMENT_DETAILS]({ commit }, payment) {
			commit(MUTATIONS.SET_PAYMENT_DETAILS, payment);
			console.log(payment)
			commit(MUTATIONS.SET_IS_LOADING, true);
			return new Promise((resolve, reject) => {
				paymentService.updateUserDetail(payment)
					.then((result) => {
					}, (error) => {
						reject(error);
					})
					.finally(() => {
						commit(MUTATIONS.SET_IS_LOADING, false);
					});
			});
		},
		[ACTIONS.UPLOAD_DOCUMENT]({ commit },{contractId,fileType ,file}) {
			return new Promise((resolve, reject) => {
				paymentService.uploadDocument(contractId,fileType,file)
				.then((result) => {
				  resolve(result);
				}, (error) => {
				  reject(error);
				});
			});
		  },
	},
	mutations: {
		[MUTATIONS.SET_PAYMENT_REQUEST](state, paymentRequest) {
			state.paymentRequest = paymentRequest;
		},
		[MUTATIONS.SET_IS_LOADING](state, loadingState) {
			state.loading = loadingState;
		},
		[MUTATIONS.SET_PAYMENT_DETAILS](state, paymentDetails) {
			state.paymentDetails = paymentDetails;
		},
	},
};


export default payment;
